// src/hooks/useAuth.js
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from "axios";

const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const token = Cookies.get('auth_token');
        // const details = localStorage.getItem('details');
        // need to add async check and remove but use the storage by default? Or on certain things check.
        if (token) {
            setIsLoggedIn(true);
            if (!details) {
                setDetails(getDetails(token))
            } else {
                setDetails(details)
            }
        }

    }, []);

    const getDetails = async (token) => {
        console.log('token: ', token);
        const url = `https://us-central1-artisan-442301.cloudfunctions.net/auth-function`;
        try {
            const response = await axios.post(url, { token: token });
            setDetails(response.data);
            localStorage.setItem('details', response.date);
        } catch (error) {
            console.error('Error fetching loggin details logging out:', error);
            setIsLoggedIn(false);
            setDetails(null);
        }
    }

    const handleLoginSuccess = (response) => {
        console.log('Login Successful! Response:', response);
        Cookies.set('auth_token', response.credential, { expires: 7 });
        setIsLoggedIn(true);
    };

    const handleLoginFailure = (error) => {
        console.log('Login Failed! Error:', error);
    };

    const handleLogout = () => {
        Cookies.remove('auth_token');
        setIsLoggedIn(false);
    };

    return {
        isLoggedIn,
        handleLoginSuccess,
        handleLoginFailure,
        handleLogout,
        details
    };
};

export default useAuth;
