import React from 'react';
import './VideoList.css';

const VideoList = ({ videos, onVideoSelect, watchedVideos }) => {
    return (
        <div className="video-list">
            {videos.map((video, index) => (
                <div
                    key={index}
                    className={`video-row ${watchedVideos[index] ? 'watched' : ''}`}
                    onClick={() => onVideoSelect(video, index)}
                >
                    <div className="video-index">{index + 1}</div>
                    <div className="video-title">{video.title}</div>
                    <div className="video-status">
                        {watchedVideos[index] ? 'Watched' : 'Not Watched'}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VideoList;
