// src/PrivacyPolicy.js
import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = 'https://storage.googleapis.com/artisan-videos/docs/TermsOfService.pdf';
        link.download = 'terms-of-service.pdf';
        link.click();
    }, []);

    return (
        <div>
            <h2>Downloading Privacy Policy...</h2>
        </div>
    );
};

export default PrivacyPolicy;
