import React, { useState } from 'react';
import VideoList from '../components/videoList/VideoList';
import VideoPlayer from '../components/videoPlayer/VideoPlayer';
import './HomePage.css';

const HomePage = () => {
    const videos = [
        { title: 'Video 1', url: 'https://storage.googleapis.com/artisan-videos/testvideo/Dana%20Is%20Off%20tonight.mp4' },
        { title: 'Video 2', url: 'https://storage.googleapis.com/artisan-videos/testvideo/Dana%20Is%20Off%20tonight.mp4' },
        { title: 'Video 3', url: 'https://storage.googleapis.com/artisan-videos/testvideo/Dana%20Is%20Off%20tonight.mp4' },
    ];

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [watchedVideos, setWatchedVideos] = useState({});

    const handleVideoSelect = (video, index) => {
        setWatchedVideos((prev) => ({
            ...prev,
            [index]: true, // Mark video as watched
        }));
        setSelectedVideo({ ...video, index }); // Pass index with the selected video
    };

    const handleReturn = () => {
        setSelectedVideo(null); // Return to the list view
    };

    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            {!selectedVideo ? (
                <VideoList
                    videos={videos}
                    onVideoSelect={handleVideoSelect}
                    watchedVideos={watchedVideos} // Pass watched state
                />
            ) : (
                <div>
                    <button onClick={handleReturn} className="return-button">
                        Return to Video List
                    </button>
                    <VideoPlayer url={selectedVideo.url} />
                    <h2>{selectedVideo.title}</h2>
                </div>
            )}
        </div>
    );
};

export default HomePage;
