import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './TopNav.css';
import { GoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import { faUser as faUserSolid } from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../hooks/useAuth";

const TopNav = () => {
    const { isLoggedIn, handleLoginSuccess, handleLoginFailure, handleLogout, details } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        console.log('toggle dropdown!');
        setShowDropdown(!showDropdown);
    };
    console.log('details', details);

    return (
        <nav className="topnav">
            <Link to="/" className="topnav-title">Artisan</Link>
            <div className="topnav-login">
                {isLoggedIn ? (
                    <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                        {details ? (
                            <img
                                src={details.picture}
                                alt="could not find picture"
                                style={{
                                    width: '3em', // Increase size
                                    height: '3em%', // Increase size
                                    borderRadius: '50%', // Make the image circular
                                    objectFit: 'cover' // Ensures the image fills the circle without distortion
                                }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '';
                                }} // Handle image load error
                            />
                        ) : (
                            <FontAwesomeIcon icon={faUserSolid} size="2x"/>
                        )}
                    </div>
                ) : (
                    <div className="dropdown">
                        <div onClick={toggleDropdown} style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faUserRegular} size="2x"/>
                        </div>
                    </div>
                )}

                {showDropdown && !isLoggedIn && (
                    <div className="dropdown-content">
                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginFailure}
                        />
                    </div>
                )}

                {showDropdown && isLoggedIn && (
                    <div className="dropdown-content">
                        <button
                            onClick={handleLogout}
                            style={{
                                padding: '10px',
                                width: '80%',
                                textAlign: 'center',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '6px'
                            }}
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default TopNav;
