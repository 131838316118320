import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNav from './components/topNav/TopNav';  // Ensure this path is correct
import HomePage from './pages/HomePage';  // Ensure this path is correct
import PrivacyPolicy from './downloads/PrivacyPolicy';  // Correct typo in folder name
import TermsOfService from './downloads/TermsOfService';  // Correct typo in folder name
import './App.css';  // Import global styles

const App = () => {
    return (
        <Router>
            {/* Navigation bar always visible */}
            <TopNav />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
        </Router>
    );
};

export default App;
